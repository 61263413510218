import { SVGIcon } from '@instructure/ui-svg-images'

function IconEyeHiddenLine(props) {
  return (
    <SVGIcon name={"IconEyeHiddenLine"} viewBox="0 0 18 18" {...props}>
      <g>
        <path
          id="Mask"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.1128 8.80146C1.76756 10.0365 2.65581 11.1115 3.69209 11.9183C3.99811 12.1498 4.2932 12.3445 4.58133 12.5184L6.57542 10.5253C6.22866 10.0365 6.01902 9.44529 6.01902 8.80246C6.01902 7.1591 7.35636 5.82177 8.99971 5.82177C9.64255 5.82177 10.2337 6.03141 10.7226 6.37816L12.501 4.59968C12.2417 4.48443 11.9814 4.3811 11.7231 4.29963C10.8418 3.98964 9.93167 3.83464 8.99971 3.83464C5.73387 3.83464 2.7333 5.73135 1.1128 8.80146ZM14.92 4.90341C16.1321 5.84929 17.1604 7.12105 17.8897 8.58059L18 8.80216L17.8907 9.02372C16.1301 12.5648 12.7242 14.7645 9.0003 14.7645C7.95607 14.7645 6.93568 14.5907 5.96397 14.2489L6.29383 13.3119C7.15824 13.616 8.06933 13.771 9.0003 13.771C12.2671 13.771 15.2667 11.8733 16.8862 8.80415C16.2325 7.56815 15.3442 6.49411 14.3089 5.68734L14.92 4.90341ZM10.9868 8.80246C10.9868 9.89837 10.0956 10.7896 8.99972 10.7896C8.63309 10.7896 8.2923 10.6833 7.99721 10.5084L10.7057 7.79896C10.8805 8.09505 10.9868 8.43485 10.9868 8.80246ZM8.99971 6.81533C7.90381 6.81533 7.01259 7.70755 7.01259 8.80246C7.01259 9.17008 7.11989 9.50988 7.29377 9.80596L10.0032 7.09651C9.70713 6.92264 9.36733 6.81533 8.99971 6.81533ZM8.9997 11.7831C10.6441 11.7831 11.9804 10.4468 11.9804 8.80246C11.9804 8.16061 11.7708 7.56845 11.425 7.08061L16.8032 1.70245L16.1007 1L13.2422 3.85848C12.8388 3.65778 12.4325 3.48093 12.036 3.35673C11.0653 3.01495 10.0439 2.84107 8.9997 2.84107C5.27582 2.84107 1.86889 5.04082 0.110286 8.58189L0 8.80345L0.110286 9.02502C0.839562 10.4846 1.8679 11.7563 3.087 12.7062C3.35328 12.9079 3.60862 13.0827 3.85801 13.2427L1.19724 15.9035L1.89969 16.6059L7.27786 11.2277C7.7657 11.5735 8.35687 11.7831 8.9997 11.7831Z"
          fill="#2D3B45"
        />
      </g>
    </SVGIcon>
  )
}

export { IconEyeHiddenLine }

