import { SVGIcon } from '@instructure/ui-svg-images'

function IconClasslink(props) {
  return (
    <SVGIcon name={"IconClasslink"} viewBox="0 0 26 18" {...props}>
        <path
          d="M7.33842 10.6455C6.34978 11.7668 7.32636 12.2973 7.32636 12.2973C7.36253 12.3214 7.3987 12.3334 7.43487 12.3455C7.29019 11.7427 7.27814 11.176 7.33842 10.6455Z"
          fill="#0A4D7F"/>
        <path
          d="M13.7405 12.0922C12.9689 12.6106 12.0767 14.3588 12.0767 14.3588H11.6065C11.9079 13.6957 12.1008 13.1411 12.2214 12.695C12.1008 12.7312 12.0405 12.7553 12.0405 12.7553C11.1122 13.0687 10.1717 13.2375 9.33984 13.2496C10.22 14.5517 11.8114 14.7928 11.8114 14.7928C14.054 15.0943 15.766 13.8645 16.6823 12.9723C14.7894 11.9234 13.7405 12.0922 13.7405 12.0922Z"
          fill="#0A4D7F"/>
        <path
          d="M21.0105 7.25757C21.0105 7.25757 22.4935 3.58031 19.1056 1.06048C19.1056 1.06048 13.6801 -2.50828 10.6539 3.44768C10.6539 3.44768 8.48371 1.27749 5.80714 2.96542C3.51639 4.40015 3.9022 6.34127 4.10716 7.31785C4.10716 7.31785 -0.209101 8.58379 0.00791772 13.1894C0.17671 16.6135 3.48022 17.6504 4.77027 17.9156C5.04758 17.9759 5.33694 18 5.62629 18H19.8049C20.0822 18 20.3595 17.9759 20.6368 17.9156C21.9389 17.6504 25.2786 16.6255 25.2183 12.9845C25.1339 8.48734 21.0105 7.25757 21.0105 7.25757ZM11.7028 15.5163C8.83335 15.4802 7.80854 13.3341 7.80854 13.3341C7.74825 13.2135 7.70003 13.105 7.6518 12.9965C6.86812 12.7072 6.39791 12.1525 6.50642 11.3206C6.61493 10.4887 7.55535 9.66889 7.55535 9.66889C8.0979 7.98096 9.32767 6.82353 9.32767 6.82353C9.32767 6.82353 10.3525 5.72638 11.4858 5.15972L13.439 5.46114C9.0142 7.26963 8.78512 10.9951 8.78512 10.9951C8.76101 11.5618 8.82129 12.032 8.95391 12.4419C9.27944 12.4178 9.52057 12.3816 9.52057 12.3816C10.6177 12.2128 11.5581 11.9235 12.3539 11.5738C12.3298 10.8384 11.9922 10.6575 11.9922 10.6575C11.9922 10.6575 11.5943 10.1994 9.94256 9.93413L10.0993 9.71711C10.0993 9.71711 10.9191 9.89796 11.8354 9.71711C12.8361 9.52421 13.7645 8.83698 14.7893 7.42636L15.2836 7.40225C14.2829 8.72847 14.2106 9.74123 14.3794 10.4043C15.3801 9.65683 15.8503 9.00577 15.8503 9.00577C17.6588 6.48595 15.151 6.87176 15.151 6.87176L15.8985 5.97957C19.7325 6.81147 16.212 10.2717 16.212 10.2717C15.8021 10.6937 15.356 11.0554 14.8978 11.3568C14.9581 11.4292 15.6212 12.1043 16.8992 12.7192C17.2368 12.3575 17.4056 12.1043 17.4056 12.1043L17.912 12.2008C14.946 15.7816 11.7028 15.5163 11.7028 15.5163ZM13.0652 8.3306C13.0532 8.75259 12.7035 9.09017 12.2815 9.10223C11.8113 9.11429 11.4376 8.72848 11.4617 8.25827C11.4737 7.83629 11.8234 7.4987 12.2454 7.48664C12.7035 7.47459 13.0893 7.8604 13.0652 8.3306Z"
          fill="#0A4D7F"/>
    </SVGIcon>
  )
}

export { IconClasslink }
