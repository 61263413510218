import { SVGIcon } from '@instructure/ui-svg-images'

function IconGoogle(props) {
  return (
    <SVGIcon name={"IconGoogle"} viewBox="0 0 18 18" {...props}>
      <mask
        id="mask0_952_1287"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <path d="M18 0H0V18H18V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_952_1287)">
        <path
          d="M17.64 9.20448C17.64 8.56629 17.5828 7.95267 17.4764 7.36353H9V10.8449H13.8436C13.635 11.9699 13.001 12.9231 12.0478 13.5613V15.8195H14.9564C16.6582 14.2527 17.64 11.9453 17.64 9.20448Z"
          fill="#4285F4"
        />
        <path
          d="M9.00004 18C11.43 18 13.4673 17.194 14.9563 15.8195L12.0477 13.5613C11.2418 14.1013 10.2109 14.4204 9.00004 14.4204C6.6559 14.4204 4.67185 12.8372 3.96409 10.71H0.957275V13.0418C2.43823 15.9832 5.48185 18 9.00004 18Z"
          fill="#34A853"
        />
        <path
          d="M3.96405 10.7101C3.78405 10.1701 3.68181 9.59325 3.68181 9.00006C3.68181 8.40687 3.78405 7.83006 3.96405 7.29006V4.95825H0.95724C0.34776 6.17325 0 7.54782 0 9.00006C0 10.4523 0.34776 11.8269 0.95724 13.0419L3.96405 10.7101Z"
          fill="#FBBC04"
        />
        <path
          d="M9.00004 3.57957C10.3213 3.57957 11.5077 4.03362 12.4405 4.92543L15.0218 2.34405C13.4632 0.89181 11.4259 0 9.00004 0C5.48185 0 2.43823 2.01681 0.957275 4.95819L3.96409 7.29C4.67185 5.16276 6.6559 3.57957 9.00004 3.57957Z"
          fill="#E94235"
        />
      </g>
    </SVGIcon>
  )
}

export { IconGoogle }
