import humps from 'humps'
import type {
  CamelCasedPropertiesDeep,
  SnakeCasedPropertiesDeep,
} from 'type-fest'

const camelizeKeys = <T>(input: T): CamelCasedPropertiesDeep<T> => {
  return humps.camelizeKeys(input)
}

const decamelizeKeys = (object, options) => {
  return humps.decamelizeKeys(object, options)
}

const snakeCaseKeys = <T>(object: T): SnakeCasedPropertiesDeep<T> => {
  return decamelizeKeys(object, { separator: '_' })
}

const snakeCaseString = (string, options = { separator: '_' }) => {
  const camelized = humps.camelize(string) // Remove empty spaces and camelize
  return humps.decamelize(camelized, options)
}

function upperFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const capitalizeWords = (string: string, splitChar = ' ') => {
  if (!string) return ''

  return string
    .split(splitChar)
    .map((word) => upperFirst(word))
    .join(' ')
}

const snakeCaseToCapitalizedWords = (string: string) => {
  return capitalizeWords(string, '_')
}

export {
  camelizeKeys,
  decamelizeKeys,
  snakeCaseKeys,
  snakeCaseString,
  upperFirst,
  capitalizeWords,
  snakeCaseToCapitalizedWords,
}
